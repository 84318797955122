import { locales, defaultLocale } from './shared/i18n/locales';

// Messages
import enMessages from '../locales/en.json';
import deMessages from '../locales/de.json';

const messages = { en: enMessages, de: deMessages };

const polyfillIntl = () => {
    const { shouldPolyfill: shouldPolyfillPluralRules } = require('@formatjs/intl-pluralrules/should-polyfill');
    if (shouldPolyfillPluralRules()) {
        require('@formatjs/intl-pluralrules/polyfill');
        require('@formatjs/intl-pluralrules/locale-data/en');
        require('@formatjs/intl-pluralrules/locale-data/de');
    }

    const {
        shouldPolyfill: shouldPolyfillRelativeTimeFormat
    } = require('@formatjs/intl-relativetimeformat/should-polyfill');
    if (shouldPolyfillRelativeTimeFormat()) {
        require('@formatjs/intl-relativetimeformat/polyfill');

        require('@formatjs/intl-relativetimeformat/locale-data/en');
        require('@formatjs/intl-relativetimeformat/locale-data/de');
    }
};

export { locales, defaultLocale, messages, polyfillIntl };
