import { compile, match } from 'path-to-regexp';

export type Locale = string;

export type RouteDefinition = {
    [locale in Locale]: string;
};

export type RouteState = {
    definition: RouteDefinition;
    parameters: Record<string, any>;
};

export function createRouteState(
    definition: RouteState['definition'],
    parameters: RouteState['parameters'] = {}
): RouteState {
    return {
        definition,
        parameters
    };
}

export function getPathForLocale(routeState: RouteState, locale: Locale): string {
    const { definition: routeDefinition, parameters: routeParameters } = routeState;

    const localeRoutePattern = routeDefinition[locale];

    const compileLocaleRoute = compile(localeRoutePattern);
    return compileLocaleRoute(routeParameters);
}

export function getFullPathForLocale(routeState: RouteState, locale: Locale): string {
    return prefixWithLocale(locale, getPathForLocale(routeState, locale));
}

export function getFullPathForLocaleOrFallback(routeStateOrRoute: RouteState | string, locale: Locale) {
    if (typeof routeStateOrRoute === 'string') {
        return prefixWithLocale(locale, routeStateOrRoute);
    }

    return getFullPathForLocale(routeStateOrRoute, locale);
}

export function createFindRouteDefinitionByRoute(routes: RouteDefinition[]) {
    return (route: string): RouteState | null => {
        for (const routeDefinition of routes) {
            for (const routeFromRouteDefinition of Object.values(routeDefinition)) {
                const matchRouteFromRouteDefinition = match(routeFromRouteDefinition);

                const matchObject = matchRouteFromRouteDefinition(route);

                if (matchObject) {
                    return {
                        definition: routeDefinition,
                        parameters: matchObject.params
                    };
                }
            }
        }

        return null;
    };
}

export function prefixWithLocale(locale: Locale, path: string): string {
    return `/${locale}${path}`;
}

export function deprefixLocale(path: string): string {
    return path.slice(3); // forward slash + 2 letters slug
}
